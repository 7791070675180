export const appHead = {"meta":[{"hid":"lang","name":"lang","content":"de"},{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"Sagen dir Schnitzeljagde, Zeltstädte, Lagerfeuer und Theater zu? Entdecke unter Freunden dein nächstes Abenteuer."},{"name":"format-detection","content":"telephone=no"},{"property":"og:title","content":"Pfadi Hochwacht Baden"},{"property":"og:type","content":"website"},{"property":"og:description","content":"Pfadi Hochwacht Baden"},{"property":"og:image","content":"https://test.hochwacht.ch/img/logo-lilie.png"},{"name":"msapplication-TileColor","content":"#ec7e1e"},{"name":"theme-color","content":"#da532c"}],"link":[{"rel":"manifest","href":"./manifest.json"},{"rel":"apple-touch-icon","sizes":"180x180","href":"/favicon/apple-touch-icon.png"}],"style":[],"script":[],"noscript":[],"title":"hochwacht.ch - Pfadi Hochwacht Baden","charset":"utf-8","viewport":"width=device-width, initial-scale=1","htmlAttrs":{"lang":"de"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const appSpaLoaderTag = "div"

export const appSpaLoaderAttrs = {"id":"__nuxt-loader"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false

export const spaLoadingTemplateOutside = false