
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.default || mod) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
  }
}
import { default as aboutaC0FMxDxqnMeta } from "/src/pages/about.vue?macro=true";
import { default as _91id_931eY5Ke4HNBMeta } from "/src/pages/agegroups/[id].vue?macro=true";
import { default as contactjt1IjhjjXuMeta } from "/src/pages/contact.vue?macro=true";
import { default as documentssOpfCIijWpMeta } from "/src/pages/documents.vue?macro=true";
import { default as donation6LwAaalqwoMeta } from "/src/pages/donation.vue?macro=true";
import { default as equipmentyhPT7pzRzfMeta } from "/src/pages/equipment.vue?macro=true";
import { default as faqytbxbScGkeMeta } from "/src/pages/faq.vue?macro=true";
import { default as _91id_93LaKmxGiNVTMeta } from "/src/pages/galleries/[id].vue?macro=true";
import { default as indexd5dWI7SzHVMeta } from "/src/pages/galleries/index.vue?macro=true";
import { default as impressum0XojDA8GKUMeta } from "/src/pages/impressum.vue?macro=true";
import { default as indexvsa0KwJWqiMeta } from "/src/pages/index.vue?macro=true";
import { default as privacypolicyKDa0cuCisYMeta } from "/src/pages/privacypolicy.vue?macro=true";
import { default as rentalidSIjkUtI0Meta } from "/src/pages/rental.vue?macro=true";
import { default as rentalimagesXBwjn5mhsqMeta } from "/src/pages/rentalimages.vue?macro=true";
import { default as _91_46_46_46slug_93sgMggEXYa2Meta } from "/src/pages/resources/[...slug].vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/src/pages/about.vue")
  },
  {
    name: "agegroups-id",
    path: "/agegroups/:id()",
    component: () => import("/src/pages/agegroups/[id].vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/src/pages/contact.vue")
  },
  {
    name: "documents",
    path: "/documents",
    component: () => import("/src/pages/documents.vue")
  },
  {
    name: "donation",
    path: "/donation",
    component: () => import("/src/pages/donation.vue")
  },
  {
    name: "equipment",
    path: "/equipment",
    component: () => import("/src/pages/equipment.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/src/pages/faq.vue")
  },
  {
    name: "galleries-id",
    path: "/galleries/:id()",
    component: () => import("/src/pages/galleries/[id].vue")
  },
  {
    name: "galleries",
    path: "/galleries",
    component: () => import("/src/pages/galleries/index.vue")
  },
  {
    name: "impressum",
    path: "/impressum",
    component: () => import("/src/pages/impressum.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/src/pages/index.vue")
  },
  {
    name: "privacypolicy",
    path: "/privacypolicy",
    component: () => import("/src/pages/privacypolicy.vue")
  },
  {
    name: "rental",
    path: "/rental",
    component: () => import("/src/pages/rental.vue")
  },
  {
    name: "rentalimages",
    path: "/rentalimages",
    component: () => import("/src/pages/rentalimages.vue")
  },
  {
    name: "resources-slug",
    path: "/resources/:slug(.*)*",
    component: () => import("/src/pages/resources/[...slug].vue")
  }
]